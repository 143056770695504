import {json, LinksFunction, LoaderFunction} from "@remix-run/node";
import {
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useLocation,
    useMatches,
    useRouteError,
} from "@remix-run/react";
import stylesheet from "~/tailwind.css?url";
import {config, library} from '@fortawesome/fontawesome-svg-core'
import {faArrowLeft, faRotateLeft, fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import faStylesheetUrl from '@fortawesome/fontawesome-svg-core/styles.css?url';
import "@cyntler/react-doc-viewer/dist/index.css";
import * as Sentry from "@sentry/remix";
import {captureRemixErrorBoundaryError, withSentry} from "@sentry/remix";
import React, {PropsWithChildren, useEffect} from "react";
import ResultXDarkLogo from "~/assets/resultx-logo-dark.webp";
import {Paper} from "~/components/paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

library.add(fas, far);
config.autoAddCss = false;

export const links: LinksFunction = () => [
    {rel: "stylesheet", href: stylesheet},
    {rel: "stylesheet", href: faStylesheetUrl},
];

export const loader: LoaderFunction = async ({request}) => {
    return json({
        env: {
            SENTRY_DSN: process.env.SENTRY_DSN,
            SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
        }
    })
}

function Layout({children}: PropsWithChildren) {
    return (
        <html lang="en" data-theme="light">
        <head>
            <meta charSet="utf-8"/>
            <title>ResultX - PaperTrail</title>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>

            {/*Favicons*/}
            <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4156FB"/>
            <meta name="msapplication-TileColor" content="#4156fb"/>
            <meta name="theme-color" content="#ffffff"/>

            <Meta/>
            <Links/>
        </head>
        <body className="min-h-screen min-w-[860px] flex flex-col">
        <ScrollRestoration/>
        {children}
        <Scripts/>
        </body>
        </html>
    );
}

function Root() {
    const {env} = useLoaderData<typeof loader>();

    useEffect(() => {
        if (!env.SENTRY_DSN || Sentry.isInitialized()) {
            return;
        }

        Sentry.init({
            dsn: env.SENTRY_DSN,
            environment: env.SENTRY_ENVIRONMENT,
            integrations: [
                Sentry.browserTracingIntegration({
                    useEffect,
                    useLocation,
                    useMatches,
                }),
                Sentry.replayIntegration(),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }, [env]);

    return (
        <Layout>
            <Outlet/>
        </Layout>
    );
}

export default withSentry(Root);

export function ErrorBoundary() {
    const error = useRouteError();
    const location = useLocation();

    useEffect(() => {
        captureRemixErrorBoundaryError(error);
        console.error(error);
    }, [error]);

    const status = (error as any)?.status;
    const statusText = (error as any)?.statusText;
    const message = (error as any)?.message || (error as any)?.data || (error as any)?.statusText;


    const displays = {
        'generic': [
            'Application error',
            'An unexpected error has occured. You can try again or go back to the previous page.'
        ],
        'not-found': [
            'Not found',
            'The page or resource you are looking for does not exist.'
        ],
    }

    let displayKey: keyof typeof displays = 'generic';
    if ((error as any)?.status == 404) {
        displayKey = 'not-found';
    }
    const display = displays[displayKey];

    return (
        <Layout>
            <div className="flex flex-grow justify-center">
                <div className="flex flex-col justify-center max-w-lg w-full">
                    <div className="flex justify-between m-2 items-center">
                        <div className="flex-grow">
                            <img slot="dark" src={ResultXDarkLogo} alt="" className="max-h-8"/>
                        </div>
                    </div>
                    <Paper className="p-8 w-full min-h-[50%]">
                        <div className="flex flex-col h-full">
                            <div className="flex-grow mb-4">
                                <h2 className="mb-8 text-2xl font-semibold text-error">
                                    {display[0]}
                                </h2>
                                <div className="mb-8 text-justify">
                                    {display[1]}
                                </div>
                                {message && (
                                    <div className="mb-4">
                                        <h3 className="font-semibold text-sm mb-1">Message</h3>
                                        <div className="text-gray-500 text-xs">
                                            <pre className="font-sans text-wrap">{message}</pre>
                                        </div>
                                    </div>
                                )}
                                <div className="mb-4">
                                    <h3 className="font-semibold text-sm mb-1">Route</h3>
                                    <div className="text-gray-500 text-xs">
                                        {location.pathname}
                                    </div>
                                </div>
                                {(status || statusText) && (
                                    <div className="mb-4">
                                        <h3 className="font-semibold text-sm mb-1">Status</h3>
                                        <div className="text-gray-500 text-xs">
                                            {status ? status + ' - ' : ''}
                                            {statusText}
                                        </div>
                                    </div>
                                )}
                                <div className="mb-4">
                                    <h3 className="font-semibold text-sm mb-1">Timestamp</h3>
                                    <div className="text-gray-500 text-xs">
                                        {new Date().toLocaleString('nl', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric'
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <a href="javascript:history.back()"
                                    className="btn btn-primary flex-1">
                                    <FontAwesomeIcon icon={faArrowLeft}/>
                                    Back
                                </a>
                                {displayKey !== 'not-found' && (
                                    <a href="javascript:window.location.reload(true)"
                                        className="btn btn-primary btn-outline flex-1">
                                        <FontAwesomeIcon icon={faRotateLeft}/>
                                        Reload
                                    </a>
                                )}
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
        </Layout>
    )
        ;
}